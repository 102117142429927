import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ContentAdmin, contentAdminTypeGuard } from "services/content.service/content.types"
import {
  changeContentVideo,
  changeContentVideoThumbnail,
  getContent,
  setContentTags
} from "services/content.service/content.service"
import { deleteContent } from "services/admin.service/admin.service"
import { Loader } from "components"
import { Helmet } from "react-helmet"
import { Trash } from "phosphor-react"
import { useConfirmationDialog } from "common/hooks/useConfirmationPrompt"
import { DialogOptions } from "common/types/contexts"
import styles from "./EditContentPage.module.scss"
import TitleRow from "./TitleRow"
import MediaEditor from "./MediaEditor/MediaEditor"
import EditContentBaseData from "./EditContentBaseData/EditContentBaseData"
import { SectionTitle } from "./SectionTitle"
import { TagEditor } from "./TagEditor"

const getDeletionDialogOptions: (action: () => void) => DialogOptions = (action: () => void) => ({
  title: "Tananyag törlése",
  message: "A tananyag törlése végleges, nem visszavonható művelet. Biztosan törölni szeretné?",
  actions: [
    {
      action,
      title: "Törlés",
      variant: "destructive",
      icon: Trash,
    },
    {
      title: "Mégse",
    },
  ],
})

type Changes = "thumbnail" | "video" | "content" | "tags"
export type ChangeSet = Record<Changes, () => Promise<unknown>>

const Section = ({
  children,
  layout = "horizontal"
}: {
  children: React.ReactNode
  layout?: "horizontal" | "vertical"
}) => (
  <div className={layout === "horizontal" ? styles.horizontalSection : styles.verticalSection}>
    {children}
  </div>
)

const EditContentPage = () => {
  const [content, setContent] = useState<ContentAdmin | null>(null)
  const [changes, setChanges] = useState<Partial<ChangeSet>>({})
  const [savingInProgress, setSavingInProgress] = useState(false)
  const [uploadStatus, setUploadStatus] = useState("Feltöltés indítása...")
  const { contentId: idParam } = useParams()
  const navigate = useNavigate()

  const addToChanges = (key: keyof ChangeSet, value: () => Promise<unknown>) => {
    setChanges((prev) => ({ ...prev, [key]: value }))
  }

  const removeFromChangeSet = (key: keyof ChangeSet) => {
    setChanges((prev) => {
      const prevCopy = { ...prev }
      delete prevCopy[key]
      return prevCopy
    })
  }

  const onDelete = async () => {
    if (content?.id) {
      await deleteContent(content.id)
      navigate("/home")
    }
  }

  const onSave = async () => {
    setSavingInProgress(true)
    if (changes.video) {
      setUploadStatus("Videó feltöltése...")
      await changes.video()
    }
    if (changes.thumbnail) {
      setUploadStatus("Előnézeti kép feltöltése...")
      await changes.thumbnail()
    }
    if (changes.tags) {
      setUploadStatus('Címkék beállítása...')
      await changes.tags()
    }
    if (changes.content) {
      setUploadStatus("Tananyag mentése...")
      await changes.content()
    }
    setChanges({})
    setSavingInProgress(false)
    navigate(`/content/${content?.id || idParam}`)
  }

  const confirmDeletion = useConfirmationDialog(getDeletionDialogOptions(onDelete))

  useEffect(() => {
    const loadContent = async (id: number) => {
      try {
        const { payload } = (await getContent(id)).data
        if (!contentAdminTypeGuard(payload)) {
          navigate("/home", { replace: true }) // user is not admin
          return
        }
        setContent(payload)
      } catch {
        navigate("/home", { replace: true })
      }
    }

    if (idParam) {
      const contentId = parseInt(idParam, 10)
      loadContent(contentId)
    }
  }, [idParam, navigate])

  if (!content) {
    return (
      <span className={styles.pageLoader}>
        <Loader size="large" variant="color" />
      </span>
    )
  }

  if (savingInProgress) {
    return (
      <>
        <Helmet>
          <title>Feltöltés - {content.title}</title>
        </Helmet>
        <div className={styles.container}>
          <div className={styles.uploadProgress}>
            <Loader size="large" variant="color" />
            <h2>Mentés folyamatban</h2>
            <h3>{uploadStatus}</h3>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>Szerkesztés - {content.title}</title>
      </Helmet>
      <div className={styles.container}>
        <TitleRow
          content={content}
          onBack={() => navigate(-1)}
          onDelete={confirmDeletion}
          onSave={onSave}
          canSave={!savingInProgress && Object.keys(changes).length > 0}
        />

        <SectionTitle>
          Alapadatok
        </SectionTitle>
        <Section layout="vertical">
          <EditContentBaseData
            addToChangeSet={addToChanges}
            contentId={content.id}
            currentState={content}
            removeFromChangeSet={removeFromChangeSet}
          />
        </Section>

        <SectionTitle>
          Előnézeti kép & videó
        </SectionTitle>
        <Section>
          {/* Thumbnail editor */}
          <MediaEditor
            type="image"
            displayUrl={content.thumbnailUrl}
            onChange={(file) =>
              addToChanges("thumbnail", () => changeContentVideoThumbnail(content.id, file))
            }
          />
          {/* Video editor */}
          <MediaEditor
            type="video"
            displayUrl={content.videoUrl}
            onChange={(file) =>
              addToChanges("video", () =>
                changeContentVideo(content.id, file, (event) =>
                  setUploadStatus(
                    `Videó feltöltése (${Math.round((event.loaded / event.total) * 100)}%)`,
                  ),
                ),
              )
            }
          />
        </Section>

        <SectionTitle>
          Címkék
        </SectionTitle>
        <TagEditor
          currentTags={content.tags}
          onChange={(tags) => setChanges((state) => ({
            ...state,
            tags: () => setContentTags(content.id, tags)
          }))}
        />

      </div>
    </>
  )
}

export default EditContentPage
