import React from 'react'
import { Content } from 'services/content.service/content.types'
import { Button } from 'components'
import { FloppyDiskBack, Trash } from 'phosphor-react'
import styles from './TitleRow.module.scss'

interface TitleRowProps {
  content: Content
  onBack: () => void
  onDelete: () => void
  onSave: () => void
  canSave: boolean
}

const TitleRow = ({
  content,
  onBack,
  onDelete,
  onSave,
  canSave,
}: TitleRowProps) => (
  <div className={styles.titleRow}>
    <div className={styles.title}>
      <h2>Tananyag szerkesztése</h2>
      <h3>{content.title} (#{content.id})</h3>
    </div>
    <div className={styles.buttonGroup}>
      <Button
        title="Vissza"
        onClick={onBack}
      />
      <Button
        title="Törlés"
        Icon={Trash}
        variant="destructive"
        onClick={onDelete}
      />
      <Button
        title="Mentés"
        Icon={FloppyDiskBack}
        onClick={onSave}
        variant="submit"
        disabled={!canSave}
        optOutOfAutoLoading
      />
    </div>
  </div>
)

export default TitleRow
