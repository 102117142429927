import React, { useEffect, useRef, useState } from 'react'
import { useHandleClickOutside } from 'common/hooks';
import { stringHelpers } from 'common/helpers';
import { classNames } from 'common/helpers/styling';
import { TextInput } from 'components';
import { Plus, Tag } from 'phosphor-react';
import { ContentTag, CreatableContentTag } from 'services/content.service/content.types';
import styles from './styles.module.scss'

interface Props {
  options: ContentTag[]
  onFinished: (value?: CreatableContentTag) => void
  initialQuery?: string
  focusOnRender?: boolean
}

export const TagSelector = ({
  options,
  onFinished,
  initialQuery,
  focusOnRender
}: Props) => {
  const [query, setQuery] = useState(initialQuery || '')
  const [isActive, setActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null)
  const { ref: clickOutsideRef } = useHandleClickOutside<HTMLDivElement>(onFinished)

  useEffect(() => {
    if(inputRef.current) {
      inputRef.current.focus()
    }
  }, [focusOnRender, inputRef])

  const handleFocus = (focus: boolean) => {
    if (focus) {
      setActive(true)
    }
  }

  const keywordSearch = (value: string, search: string) => {
    const keywords = search.trim().toLowerCase().split(' ')
    return keywords.every((kw) => value.toLowerCase().includes(kw))
  }

  const optionList = options.filter(
    (opt) => query === '' ? true : keywordSearch(opt.name, query)
  ).map((opt) => (
    <div
      key={`dropdown-option-${opt.name}`}
      className={styles.option}
      onClick={() => onFinished(opt)}
    >
      <Tag weight='bold' />
      {opt.name}
    </div>
  ))

  const newValueOption = (query === '' || optionList.length > 0) ? null : (
    <div
      key={'dropdown-option-new'}
      className={styles.option}
      onClick={() => onFinished({
        name: stringHelpers.capitalize(query.trim())
      })}
    >
      <Plus weight='bold' />
      {query.trim()}
    </div>
  )

  const placeholder = (query !== '' || optionList.length > 0) ? null : (
    <div
      key={'dropdown-option-placeholder'}
      className={classNames(styles.option, styles.placeholder)}
    >
      Kezdjen el gépelni...
    </div>
  )

  return (
    <div
      className={classNames(styles.container)}
      ref={clickOutsideRef}
    >
      <TextInput
        value={query}
        onChange={setQuery}
        inputRef={inputRef}
        className={styles.inputContainer}
        inputClassName={classNames(styles.input)}
        onFocusChange={handleFocus}
      />
      { (isActive || !isActive) && (
        <div className={styles.options}>
          {optionList}
          {newValueOption}
          {placeholder}
        </div>
      )}
    </div>
  );
};
