import React, { useContext, useEffect, useState } from 'react'
import { Card, Button, TextInput } from 'components'
import { User, MagnifyingGlass, Sliders } from 'phosphor-react'
import { useLocation, useNavigate } from 'react-router-dom'
import { UserMenu } from 'components/PageBase/UserMenu'
import { UserContext } from 'App'
import { classNames } from 'common/helpers/styling'
import { logout } from 'services/user.service/User.service'
import { useQueryParamAsState } from 'common/hooks/useQueryParamAsState'
import { FilterModal } from 'components/PageBase/Header/FilterModal'
import { ContentTag } from 'services/content.service/content.types'
import { getAvailableTags } from 'services/content.service/content.service'
import styles from './Header.module.scss'

export const Header: React.FC = () => {
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const { user, setUser } = useContext(UserContext)

  const [userMenuOpen, setUserMenuOpen] = useState(false)
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useQueryParamAsState('search')
  const [availableTags, setAvailableTags] = useState<ContentTag[]>([])

  useEffect(() => {
    getAvailableTags().then((res) => setAvailableTags(res.data.payload))
  }, [])

  const openAuthModal = () => {
    navigate({ pathname: pathname + '/auth/login', search })
  }

  const handleLogoClick = () => {
    navigate('/home')
  }

  const handleStatisticsClick = () => {
    navigate('/admin/statistics')
    setUserMenuOpen(false)
  }

  const handleProfileSettingsClick = () => {
    navigate('/profile')
    setUserMenuOpen(false)
  }

  const handleLogoutClick = async () => {
    await logout()
    setUser(null)
    setUserMenuOpen(false)
  }

  const handleUserButtonClick = () => {
    if (user) {
      setUserMenuOpen(true)
    } else {
      openAuthModal()
    }
  }

  return (
    <header>
      <Card className={styles.header}>
        <div className={styles.logo} onClick={handleLogoClick}>
          <div className={styles.image} />
          <div className={styles.text}>
            <h1>Tudástár</h1>
            <h2>Édes Gyermekünk Alapítvány</h2>
          </div>
        </div>
        <div className={styles.search}>
          <TextInput
            value={searchQuery || ''}
            placeholder="Keresés..."
            StartIcon={MagnifyingGlass}
            className={styles.searchField}
            onChange={setSearchQuery}
          />
          <Button
            iconWeight="bold"
            Icon={Sliders}
            onClick={() => setFilterModalOpen(!filterModalOpen)}
            className={styles.filterButton}
            pressed={filterModalOpen}
          />
        </div>
        <Button
          Icon={(props) => <User {...props} color="var(--user-button-color)" />}
          onClick={handleUserButtonClick}
          className={classNames(styles.userButton, { [styles.loggedIn]: user })}
          title={user ? user.firstName : undefined}
        />
        <UserMenu
          open={userMenuOpen}
          onClose={() => setUserMenuOpen(false)}
          onProfileSettings={handleProfileSettingsClick}
          onLogout={handleLogoutClick}
          onStatistics={user?.role === 'Admin' ? handleStatisticsClick : undefined}
        />
        <FilterModal open={filterModalOpen} availableTags={availableTags} />
      </Card>
    </header>
  )
}
