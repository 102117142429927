import React, { useCallback, useContext, useEffect } from "react"
import Helmet from "react-helmet"
import { Loader, ContentCard } from "components"
import { useResetContentScrollPosition } from "common/hooks/useResetContentScrollPosition"
import { getenv } from "common/helpers"
import { ContentListContext, UserContext } from "App"
import { ContentSummary } from "services/content.service/content.types"
import { debounce } from "lodash"
import { searchContent } from "services/content.service/content.service"
import {
  OrderQueryParam,
  QUERY_PARAMS,
  UploadedAfterQueryParam,
  useOrderQueryParamAsState,
  useQueryParamAsState,
  useSearchQueryParamAsState,
  useTagIdsQueryParamAsState,
} from 'common/hooks/useQueryParamAsState'
import styles from './HomePage.module.scss'

const sortContentList = (
  contentA: ContentSummary,
  contentB: ContentSummary,
  ordering: OrderQueryParam,
) => {
  if (ordering === 'date_desc') {
    return contentA.createdDate - contentB.createdDate
  }
  if (ordering === 'date_asc') {
    return contentB.createdDate - contentA.createdDate
  }
  return 0
}

const getTimestampFromUploadedAfterQueryParam = (param: UploadedAfterQueryParam | string) => {
  switch (param) {
    case 'last_hour':
      return new Date().setHours(new Date().getHours() - 1)
    case 'last_day':
      return new Date().setDate(new Date().getDate() - 1)
    case 'last_week':
      return new Date().setDate(new Date().getDate() - 7)
    case 'last_month':
      return new Date().setMonth(new Date().getMonth() - 1)
    default:
      return null
  }
}

const filterContentList = (
  tagIds: number[],
  content: ContentSummary,
  uploadedAfter: string | null,
  creatorBy: string | null,
) => {
  const uploadedAfterTimestamp = getTimestampFromUploadedAfterQueryParam(uploadedAfter)
  if (tagIds?.length && tagIds.some((id) => content.tags.some((cTag) => cTag.id === id))) {
    return false
  }
  if (uploadedAfterTimestamp && content.createdDate < uploadedAfterTimestamp) {
    return false
  }
  if (creatorBy && content.createdByEmail !== creatorBy) {
    return false
  }
  return true
}

const HomePage: React.FC = () => {
  const {
    contentList, 
    setContentList,
    lastQuery,
    setLastQuery
  } = useContext(ContentListContext)
  const { user } = useContext(UserContext)
  const [searchQuery] = useSearchQueryParamAsState()
  const [ordering] = useOrderQueryParamAsState()
  const [tagIds] = useTagIdsQueryParamAsState()
  const [uploadedAfter] = useQueryParamAsState(QUERY_PARAMS.uploadedAfter)
  const [creatorBy] = useQueryParamAsState(QUERY_PARAMS.createdBy)

  useResetContentScrollPosition()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateContentList = useCallback(
    debounce(async (query: string | null) => {
      if (query !== lastQuery) {
        setContentList(null)
        const { payload } = (await searchContent(query || "")).data
        setLastQuery(query)
        setContentList(payload.contentList)
      }
    }, 500),
    [],
  )

  useEffect(() => {
    debouncedUpdateContentList(searchQuery)
  }, [searchQuery, debouncedUpdateContentList, user])

  if (!contentList) {
    return (
      <div className={styles.loaderContainer}>
        <Loader className={styles.loader} size="large" variant="color" />
      </div>
    )
  }

  const contentCards = contentList
    .sort((a, b) => sortContentList(a, b, ordering))
    .filter((c) => filterContentList(tagIds, c, uploadedAfter, creatorBy))
    .map((content) => <ContentCard key={"content-" + content.id} content={content} />)

  return (
    <>
      <Helmet>
        <title>{`${getenv('HELMET_TITLE')} | Főoldal`}</title>
      </Helmet>
      <div className={styles.contentList}>{contentCards}</div>
    </>
  )
}

export default HomePage
