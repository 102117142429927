export enum UserRolesEnum {
    Public = 1,
    Generic = 2,
    User = 3,
    Teacher = 4,
    Admin = 5
}

export interface ContentSearchResult {
  contentList: ContentSummary[]
  tagList: ContentTag[]
}

export interface ContentSummary {
  id: number
  title: string
  description: string
  thumbnailUrl: string
  seriesId: number | null
  seriesLength: number
  seriesIndex: number | null
  attachmentCount: number
  videoDuration: number
  quizCompleted: boolean
  tags: ContentTag[]
  createdDate: number
  createdByEmail: string
}

export type AttachmentType = 'image' | 'document' | 'presentation'
export type ContentTag = { id: number; name: string }
export type CreatableContentTag = Omit<ContentTag, 'id'> & { id?: number; }

export interface Attachment {
  title: string
  url: string
  type: AttachmentType
}

export type Content = ContentSummary & {
  videoUrl: string
  quiz: QuizQuestion[]
  attachments: Attachment[]
}

export type ContentAdmin = Content & {
  userRoleId: number
}

export interface QuizAnswer {
  text: string
  id: number
}

export interface QuizQuestion {
  answers: QuizAnswer[]
  correctAnswerId: number
  title: string
}

export const contentAdminTypeGuard = (content: Content | ContentAdmin): content is ContentAdmin => {
  return 'userRoleId' in content
}
